import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.inputTarget.style.resize = 'none';
    this.inputTarget.style.overflow = 'hidden';
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight + 4}px`;
  }

  resize(event){
    console.log('11');
    event.target.style.height = '5px';
    event.target.style.height =  `${event.target.scrollHeight + 4}px`;
  }
}