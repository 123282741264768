import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'pricingPlan',
    'selectedPricingPlanUuid'
  ]

  onTargetClick(e) {
    e.stopPropagation()
    e.preventDefault()
    this.pricingPlanTargets.forEach(plan => {
      plan.classList.remove('selected')
    });
    e.currentTarget.classList.add('selected')
    this.selectedPricingPlanUuidTarget.value = e.params.pricingPlanUuid
  }
}
