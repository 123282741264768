import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'promocodeForm',
    'hasPromocodeBtn'
  ]

  showPromocodeForm() {
    if (this.promocodeFormTarget && this.hasPromocodeBtnTarget) {
      this.promocodeFormTarget.classList.remove('d-none')
      this.hasPromocodeBtnTarget.classList.add('d-none')
    }
  }
}