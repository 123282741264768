import { Controller } from "@hotwired/stimulus"
import IMask from 'imask';

export default class extends Controller {
  static targets = [
    'phoneNumberMasked', 'hiddenUnmaskedPhoneNumber'
  ]
  static values = {
    phoneMasks: Array
  }

  connect() {
    let maskOptions = {
      mask: this.phoneMasksValue,
      dispatch: function (appended, dynamicMasked) {
        var number = (dynamicMasked.value + appended).replace(/\D/g,'');
        return dynamicMasked.compiledMasks.find(function (m) {
          return number.indexOf(m.startsWith) === 0;
        });
      }
    };

    this.maskMobileNumberMask = IMask(this.phoneNumberMaskedTarget, maskOptions);
    this.maskMobileNumberMask.on('accept', () => {
      this.hiddenUnmaskedPhoneNumberTarget.value = this.maskMobileNumberMask.unmaskedValue
    })
  }
}
