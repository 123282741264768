import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'interest'
  ]

  onTargetClick(e) {
    e.stopPropagation()
    e.preventDefault()
    let newVisible;
    this.interestTargets.forEach(interest => {
      interest.classList.add('d-none')
      if (interest.classList.contains(e.params.nextInterest)) {
        newVisible = interest
      }
    });
    if (newVisible) {
      newVisible.classList.remove('d-none')
    }
  }
}
