import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'paymentCard',
    'selectedPaymentCard'
  ]

  onTargetClick(e) {
    e.stopPropagation()
    e.preventDefault()
    if (!e.params.approved) return
    this.paymentCardTargets.forEach(card => {
      card.classList.remove('selected')
    });
    e.currentTarget.classList.add('selected')
    this.selectedPaymentCardTarget.value = e.params.selectedPaymentCard
  }
}
