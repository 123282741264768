import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  loaderContainer = null
  connect() {
    window.addEventListener("beforeunload", this.beforeUnloadListener);
  }
  showLoader() {
    this.loaderContainer = document.createElement('div')
    this.loaderContainer.classList.add('action-loading')
    this.loaderContainer.innerHTML = `
      <div class="lds-dual-ring"></div>
    `
    document.querySelector('body').appendChild(this.loaderContainer)
  }
  beforeUnloadListener(event) {
    if (this.loaderContainer) {
       this.loaderContainer.classList.add('d-none')
    }
  };
}