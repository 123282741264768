import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = 
    [ 
      'uploader', 'filesContainer'
    ]

  connect() {
  }
  filesChanged(e) {
    let files = this.uploaderTarget.files
    let size = 0
    for(let i = 0; i < files.length; i++) {
      size += files[i].size
    }

    // 100 mb
    if (files.length > 10 || size > 104857600) {
      this.clearUploader()
      alert('Возможно загрузить максимум 10 файлов, общим размером не более 100 мб')
    } else if (files.length > 0) {
      this.fillInFileContainer()
    } else {
      this.clearUploader()
    }
  }
  clearUploader() {
    this.uploaderTarget.value = ''
    this.filesContainerTarget.innerHTML = ''
  }
  fillInFileContainer() {
    this.filesContainerTarget.innerHTML += `
      <div class='files-container'>
        <div>
        Загружено: ${this.uploaderTarget.files.length} шт.
        </div>
        <div class='remove-file' data-action='click->files-multiuploader#clearUploader'>
        Удалить
        </div>
      </div>
    `
  }
}
