import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  showConfirmation(e) {
    if (!confirm(e.params.message)) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
}
