import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  copy(e) {
    e.preventDefault()
    e.stopPropagation()
    navigator.clipboard.writeText(e.params.value).then(() => {
    },() => {
      alert('Ошибка! Не могу скопировать :(')
    })
  }
}
