import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'nameInput',
    'emailInput',
    'phoneNumberInput',
    'submitBtn'
  ]

  connect() {
    this.handleSubmitBtnState()
  }

  handleSubmitBtnState() {
    if (this.nameInputTarget.value.length != 0 && this.emailInputTarget.value.length != 0
          && this.phoneNumberInputTarget.value.length > 6) {
      this.submitBtnTarget.removeAttribute('disabled', false)
    } else {
      this.submitBtnTarget.setAttribute('disabled', true)
    }
  }
}
