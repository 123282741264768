import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'passwordInput',
    'passwordConfirmationInput',
    'submitBtn'
  ]

  connect() {
    this.handleSubmitBtnState()
  }

  handleSubmitBtnState() {
    if (this.passwordInputTarget.value.length >= 6 
          && this.passwordConfirmationInputTarget.value.length >= 6) {
      this.submitBtnTarget.removeAttribute('disabled', false)
    } else {
      this.submitBtnTarget.setAttribute('disabled', true)
    }
  }
}
