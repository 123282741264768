import { Controller } from "@hotwired/stimulus"
import TimeMe from 'timeme.js'
import consumer from "../channels/consumer"
export default class extends Controller {
  static values = {
    contentSlug: String
  }
  interval = null;
  
  connect() {
    TimeMe.initialize({
	    currentPageName: this.contentSlugValue,
	    idleTimeoutInSeconds: 15 //seconds
    });
    TimeMe.startTimer(this.contentSlugValue);
    this.connectToWebsocket(this.contentSlugValue)
  }
  disconnect() {
    if (this.interval) clearInterval(this.interval);
  }
  connectToWebsocket(slug) {
    consumer.subscriptions.create({ channel: "ViewContentChannel", content_slug: this.contentSlugValue }, {
      connected() {
        this.perform("receive", { timeOnPage: TimeMe.getTimeOnPageInSeconds(slug) })
        this.interval = setInterval(() => {
          this.perform("receive", { timeOnPage: TimeMe.getTimeOnPageInSeconds(slug) })
        }, 5000);
      },

      disconnected() {
        if (this.interval) clearInterval(this.interval);
      },
    
      rejected() {
        console.log('ac', 'rejected');
        if (this.interval) clearInterval(this.interval);
      },
    })
  }
}
